import { ApiHelper } from '@/utils';
import { IResponse } from '../../common.services';

class AccountServiceRoute {
  static readonly UPLOAD_AVATAR_LIST = '/affiliate-department/users/avatar';
}

export const uploadAvatar = async (file: any) => {
  return ApiHelper.upload<IResponse<any>, any>(AccountServiceRoute.UPLOAD_AVATAR_LIST, { file });
};
