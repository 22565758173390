import TabNormal from '@/components/TabNormal';
import { ICommissionClaimStatistic, getCommissionClaimStatistic, postCommissionClaim } from '@/services/commission';
import { userProfileState } from '@/store/user/selector';
import { formatNumber, handleStringify } from '@/utils';
import { Button, Col, Row, Spin, TabsProps } from 'antd';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ClaimCommission from './dialog.claim-commission';
import ClaimRequestManagement from './tabs.claim-request-management/ClaimRequestManagement';
import Transactions from './tabs.transactions/Transactions';
import { useCountdown } from '@/hooks/useCountdown';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { requestUserProfile } from '@/store/user/actions';

const CommissionClaim: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(userProfileState);
  const { minutes, seconds, hours, days } = useCountdown(String(moment(profile.lastClaimTime).add(7, 'days')));
  const history = useHistory();
  const parsedQuery = queryString.parse(history.location.search);
  const { tab } = parsedQuery;
  const [loading, setLoading] = useState(true);
  const [claimLoading, setClaimLoading] = useState(false);
  const [totalCommission, setTotalCommission] = useState<string>('0');
  const [openClaimCommission, setOpenClaimCommission] = useState(false);
  const [dataSource, setDataSource] = useState({} as ICommissionClaimStatistic);
  const [currentActiveKey, setCurrentActiveKey] = useState(tab ? String(tab) : '1');
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t(`Transactions`),
      children: currentActiveKey === '1' && <Transactions />,
    },
    {
      key: '2',
      label: t(`Claim request management`),
      children: currentActiveKey === '2' && <ClaimRequestManagement />,
    },
  ];
  const getData = async () => {
    setLoading(true);
    const response = await getCommissionClaimStatistic([]);
    if (response && response.success) {
      setDataSource(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!profile.isClaimable && !minutes && !seconds && !hours && !days) {
      dispatch(requestUserProfile());
    }
  }, [days, dispatch, hours, minutes, profile.isClaimable, seconds]);

  const handleChangeTab = (value: string) => {
    handleStringify({ tab: value }, history);
    setCurrentActiveKey(value);
  };
  const handleOpenClaimCommission = () => {
    setOpenClaimCommission((current: boolean) => !current);
  };

  const renderTextClaim = useCallback(() => {
    if (profile.isClaimable) {
      return t('Claim commissions');
    }
    return (
      <span>
        {`${days}d:${hours}h:${minutes >= 10 ? minutes : `0${minutes}`}m:${seconds >= 10 ? seconds : `0${seconds}`}s`}{' '}
        to Claim
      </span>
    );
  }, [days, hours, minutes, profile.isClaimable, seconds, t]);

  const onClaimCommission = async () => {
    if (!dataSource) return;

    setClaimLoading(true);
    const response = await postCommissionClaim();
    if (response.success) {
      setTotalCommission(dataSource.totalCommission);
      getData();
      dispatch(requestUserProfile());
      handleOpenClaimCommission();
    }
    setClaimLoading(false);
  };

  return (
    <div className="titan-page commission-page">
      <span className="titan-page__title">{t('Commission claim')}</span>

      <div className="commissions-top">
        {loading ? (
          <div style={{ minHeight: 256, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <Spin />
          </div>
        ) : (
          <Row>
            <Col md={6} className="commissions-top-border">
              <div>
                <p>{t('Total commissions')}</p>
                <h2>${formatNumber(parseFloat(dataSource?.totalCommission))}</h2>
              </div>
            </Col>

            <Col md={12} className="commissions-top-border">
              <div>
                <div className="commissions-top-row">
                  <p>{t('Total purchase package commission')}</p>
                  <p className="value">{formatNumber(parseFloat(dataSource?.totalPurchasePkgCommissionAmount))}</p>
                </div>
                <div className="commissions-top-row">
                  <p>{t('Total profit sharing commission')}</p>
                  <p className="value">{formatNumber(parseFloat(dataSource?.totalProfitSharingCommissionAmount))}</p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <Button
                disabled={!profile.isClaimable || parseInt(dataSource?.totalCommission) === 0 || claimLoading}
                loading={loading}
                htmlType="submit"
                type="primary"
                className="btn-claim-commissions btn"
                onClick={onClaimCommission}
              >
                {renderTextClaim()}
              </Button>
            </Col>
          </Row>
        )}
      </div>

      <TabNormal items={items} onChange={handleChangeTab} activeKey={currentActiveKey} />

      <ClaimCommission
        showProcessing={parseInt(totalCommission) > 1000}
        open={openClaimCommission}
        close={handleOpenClaimCommission}
        loading={claimLoading}
      />
    </div>
  );
};

export default CommissionClaim;
