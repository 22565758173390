import { Tabs } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import TopTradingBotWallets from './components/TopTradingBotWallets';
import Transactions from './components/Transactions';

const MyWallet: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const parsedQuery = queryString.parse(history.location.search);

  const { tabActive } = parsedQuery;
  const [selectedTab, setSelectedTab] = useState(tabActive ? String(tabActive) : 'top_trading_bot_wallet');
  const itemTabs = [
    {
      label: 'Top trading bot wallet',
      key: 'top_trading_bot_wallet',
      children: <></>,
    },
    { label: 'Transaction', key: 'transaction', children: <></> },
  ];

  const onClickTabs = (e: string) => {
    setSelectedTab(e);
  };

  return (
    <div className="titan-page">
      <span className="titan-page__title">{t('My wallet')}</span>

      <div className="titan-tabs" style={{ marginTop: 0 }}>
        <Tabs activeKey={selectedTab} items={itemTabs} destroyInactiveTabPane={true} onTabClick={onClickTabs} />
      </div>

      {selectedTab === 'top_trading_bot_wallet' ? <TopTradingBotWallets /> : <Transactions />}
    </div>
  );
};

export default MyWallet;
