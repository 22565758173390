import React from 'react';

import { Dialog } from '@/components';
import QRCode from 'qrcode.react';

interface IDialogReferralQRCode {
  isOpen: boolean;
  referralCode: string;
  onClose: () => void;
}

export const DialogReferralQRCode: React.FC<IDialogReferralQRCode> = ({ isOpen, referralCode, onClose }) => {
  return (
    <Dialog isOpen={isOpen} handleClose={onClose} className="titan-modal" isCloseButton>
      <>
        <div className="titan-modal__content center">
          <div className="title">Referral QR code</div>

          <QRCode
            value={referralCode}
            className="qr-code qr-code-referral"
            bgColor="#252525"
            fgColor="white"
            size={207}
          />
        </div>
      </>
    </Dialog>
  );
};
