import React, { useState } from 'react';

import { Alert, Button, Form, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { InputPassword, Dialog } from '@/components';
import { changePassword } from '@/services/auth';
import { validatePassword } from '@/utils';

interface IDialogChangePassword {
  openDialog: boolean;
  toggleDialog: () => void;
}

export const DialogChangePassword: React.FC<IDialogChangePassword> = ({ openDialog, toggleDialog }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [validateForm, setValidateForm] = useState<Map<string, boolean>>(new Map());
  const [error, setError] = useState<string>();

  const openNotificationWithIcon = (type: 'success') => {
    notification[type]({
      message: t('Change password successfully'),
      description: t('The password linked to your account has been changed successfully'),
    });
  };

  const onFinish = async (values: any) => {
    const payload = { oldPassword: values.old_password, newPassword: values.new_password };
    const response = await changePassword(payload);
    if (response.success) {
      toggleDialog();
      setValidateForm(new Map());
      openNotificationWithIcon('success');
    } else {
      setError(response.message);
    }
  };

  const handleValidate = (nameInput: string, err: boolean) => {
    const newMapData = new Map<string, boolean>();
    newMapData.set(nameInput, err);
    const merged = new Map([...validateForm, ...newMapData]);
    setValidateForm(merged);
  };

  return (
    <Dialog
      isOpen={openDialog}
      handleClose={toggleDialog}
      className="titan-modal titan-modal--change-pw titan-modal--form-mobile"
      isCloseButton
    >
      <div className="change-pass-form inside">
        <div className="change-pass-form__top">
          <h2 className="change-pass-form__title">{t('Change password')}</h2>
        </div>
        <Form
          name="form-reset-password"
          initialValues={{}}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          className="titan-form"
          form={form}
        >
          {error && (
            <Form.Item noStyle>
              <Alert message={String(error)} type="error" className="titan-alert titan-alert--error" />
            </Form.Item>
          )}
          <Form.Item
            label={t('Old password')}
            name="old_password"
            rules={[
              {
                validator: (_rule: any, value: string = '') => {
                  if (value.length === 0) {
                    handleValidate('old_password', true);
                    return Promise.reject(t('This field is required'));
                  }
                  if (value.length !== 0) {
                    if (!validatePassword(value)) {
                      handleValidate('old_password', true);
                      return Promise.reject(
                        t(
                          'Sorry, password must be between 8 and 30 characters long with at least 1 uppercase letter and 1 number',
                        ),
                      );
                    }
                  }
                  handleValidate('old_password', false);
                  return Promise.resolve();
                },
              },
            ]}
            normalize={(value) => {
              return value.trim();
            }}
          >
            <InputPassword placeholder={t('Enter old password') || ''} err={validateForm?.get('old_password')} />
          </Form.Item>
          <Form.Item
            label={t('New password')}
            name="new_password"
            rules={[
              {
                validator: (_rule: any, value: string = '') => {
                  if (value.length === 0) {
                    handleValidate('new_password', true);
                    return Promise.reject(t('This field is required'));
                  }
                  if (value.length !== 0) {
                    if (!validatePassword(value)) {
                      handleValidate('new_password', true);
                      return Promise.reject(
                        t(
                          'Sorry, password must be between 8 and 30 characters long with at least 1 uppercase letter and 1 number',
                        ),
                      );
                    }
                  }
                  handleValidate('new_password', false);
                  return Promise.resolve();
                },
              },
            ]}
            normalize={(value) => {
              return value.trim();
            }}
          >
            <InputPassword placeholder={t('Enter new password') || ''} err={validateForm?.get('new_password')} />
          </Form.Item>
          <Form.Item label={t('Confirm password')} shouldUpdate>
            {({ getFieldValue, getFieldError }) => {
              return (
                <Form.Item
                  name="confirm_password"
                  noStyle
                  rules={[
                    {
                      validator: (_rule: any, value: string = '') => {
                        if (getFieldValue('new_password') !== value) {
                          handleValidate('confirm_password', true);
                          return Promise.reject(t('Password doesn’t match. Please try again.'));
                        }
                        handleValidate('confirm_password', false);
                        return Promise.resolve();
                      },
                    },
                  ]}
                  normalize={(value) => {
                    return value.trim();
                  }}
                >
                  <InputPassword
                    placeholder={t('Confirm new password') || ''}
                    err={validateForm?.get('confirm_password')}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item className="titan-form__item mb-0 actions" shouldUpdate>
            {({ getFieldValue, getFieldError }) => {
              const getDisabled = () => {
                if (
                  !getFieldValue('old_password') ||
                  !getFieldValue('new_password') ||
                  !getFieldValue('confirm_password')
                )
                  return true;
                if (
                  (getFieldValue('old_password') && getFieldError('old_password').length > 0) ||
                  (getFieldValue('new_password') && getFieldError('new_password').length > 0) ||
                  (getFieldValue('confirm_password') && getFieldError('confirm_password').length > 0)
                )
                  return true;
              };
              return (
                <>
                  <Button
                    ghost
                    className="titan-form__submit mr-16 btn-default-outline btn-w-auto"
                    onClick={() => {
                      toggleDialog();
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="titan-form__submit btn-w-auto"
                    disabled={getDisabled()}
                  >
                    {t('Save')}
                  </Button>
                </>
              );
            }}
          </Form.Item>
        </Form>
      </div>
    </Dialog>
  );
};
export default DialogChangePassword;
