import { BuildingIcon } from '@/assets';
import { Button, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggleValue } from '@/hooks';
import { InfoCircleFilled } from '@ant-design/icons';
import { getMyWallet } from '@/services/settings/my-wallet';
import DialogWithdraw from './DialogWithdraw';

interface IMyWalletBalance {
  refetchAssets: () => void;
}

const MyWalletBalance: React.FC<IMyWalletBalance> = ({ refetchAssets }) => {
  const { t } = useTranslation();
  const [myWallet, setMyWallet] = useState({ currentBalance: null, balanceOfMembers: null });

  const [openWithDraw, toggleWithdraw] = useToggleValue(false);

  const fetchMyWallet = () => {
    getMyWallet()
      .then((res) => {
        const data = res.data;
        setMyWallet(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchMyWallet();
  }, []);

  return (
    <div className="block-wallet">
      <div className="block-wallet__detail">
        <div className="box-wallet">
          <div className="box-wallet__item">
            <div className="icon">
              <BuildingIcon />
            </div>
            <div className="info">
              <p className="title">
                {t('Your current balance')}
                <Tooltip title={t('Your current balance')}>
                  <InfoCircleFilled />
                </Tooltip>
              </p>
              <p className="volume">${myWallet?.currentBalance}</p>
            </div>
            <div className="actions">
              <Button type="primary" className="btn btn--fill" onClick={toggleWithdraw}>
                {t('Withdraw')}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {openWithDraw && (
        <DialogWithdraw
          openDialog={openWithDraw}
          setOpenDialog={toggleWithdraw}
          onReloadBalance={() => {
            fetchMyWallet();
            refetchAssets();
          }}
        />
      )}
    </div>
  );
};

export default MyWalletBalance;
