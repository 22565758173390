export enum MoneyDefault {
  USDT = 'USDT',
  BTC = 'BTC',
  KRW = 'KRW',
}

export enum StatusRequest {
  PENDING = 1,
  APPROVED,
  REJECTED,
}

export enum FormatTime {
  Date = 'DD/MM/YYYY',
  DateFull = 'DD/MM/YYYY HH:mm:ss',
}

export const ListStatus = [
  {
    id: 1,
    key: 'PENDING',
    name: 'Pending',
  },
  {
    id: 2,
    key: 'APPROVED',
    name: 'Approved',
  },
  {
    id: 3,
    key: 'REJECTED',
    name: 'Rejected',
  },
];

export const ListStatusVipPackage = [
  {
    id: 1,
    key: 'PENDING',
    name: 'Pending',
  },
  {
    id: 2,
    key: 'ACTIVE',
    name: 'Active',
  },
  {
    id: 3,
    key: 'EXPIRED',
    name: 'Expired',
  },
];

export enum StatusUser {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  LOCKED = 'locked',
}

export const listStatusUser = [
  {
    id: 1,
    key: 'inactive',
    name: 'Inactive',
  },
  {
    id: 2,
    key: 'active',
    name: 'Active',
  },
  {
    id: 3,
    key: 'locked',
    name: 'Locked',
  },
];

export enum EUserRole {
  MARKETING_COMPANY = 'MARKETING_COMPANY',
  DIVISION_LEADER = 'DIVISION_LEADER',
  TEAM_LEADER = 'TEAM_LEADER',
  SUB_AFFILIATE = 'SUB_AFFILIATE',
}

export enum StatusKeys {
  Inactive = 'inactive',
  Active = 'active',
}

export const listStatusSubAffilicate = [
  {
    id: 1,
    key: StatusKeys.Active,
    name: 'Active',
  },
  {
    id: 2,
    key: StatusKeys.Inactive,
    name: 'Disabled',
  },
];

export enum StatusClaimRequestKeys {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}

export const listStatusClaimRequest = [
  {
    id: 1,
    key: StatusClaimRequestKeys.Approved,
    name: 'Approved',
    color: '#12B76A',
  },
  {
    id: 2,
    key: StatusClaimRequestKeys.Pending,
    name: 'Pending',
    color: '#FDB022',
  },
  {
    id: 3,
    key: StatusClaimRequestKeys.Rejected,
    name: 'Rejected',
    color: '#F04438',
  },
];

export const ObjStatusClaimRequestFilter = {
  label: 'Status',
  filterKey: 'status',
  items: [
    {
      name: 'All',
      key: '',
    },
    {
      name: 'Approved',
      key: StatusClaimRequestKeys.Approved,
    },
    {
      name: 'Rejected',
      key: StatusClaimRequestKeys.Rejected,
    },
    {
      name: 'Pending',
      key: StatusClaimRequestKeys.Pending,
    },
  ],
};

export enum PositionSubAffiliateType {
  DIVISION_LEADER = 'DIVISION_LEADER',
  TEAM_LEADER = 'TEAM_LEADER',
  SUB_AFFILIATE = 'SUB_AFFILIATE',
}

export const listPositionSubAffilicate = [
  {
    id: 1,
    key: PositionSubAffiliateType.DIVISION_LEADER,
    name: 'Sale division manager',
    acceptRole: [EUserRole.MARKETING_COMPANY],
  },
  {
    id: 2,
    key: PositionSubAffiliateType.TEAM_LEADER,
    name: 'Sale team leader',
    acceptRole: [EUserRole.DIVISION_LEADER, EUserRole.MARKETING_COMPANY],
  },
  {
    id: 3,
    key: PositionSubAffiliateType.SUB_AFFILIATE,
    name: 'Sale staff',
    acceptRole: [EUserRole.DIVISION_LEADER, EUserRole.MARKETING_COMPANY, EUserRole.TEAM_LEADER],
  },
];

export enum StatusVipPackageRequest {
  PENDING = 1,
  ACTIVE,
  EXPIRED,
}

export enum ERoleAdmin {
  SUPER_ADMIN = 'superadmin',
  TITAN_ADMIN = 'titan_admin',
  TOP_TRADING_ADMIN = 'top_trading_admin',
}

export enum EBotPackageType {
  BASIC = '1',
  ADVANCE = '2',
  PREMIUM = '3',
  VIP = '4',
  PROFIT_SHARING = '5',
}

export enum EOrganizationPosition {
  NORMAL_USER = 'Titan Trading',
  STAFF = 'TT Bot',
  JUNIOR_MANAGER = 'TT Bot',
  TEAM_MANAGER = 'TT Bot',
  CENTER_DIRECTOR = 'TT Bot',
  REGIONAL_HEADQUATER_MANAGER = 'TT Bot',
}

export enum EOrganizationPositionTitle {
  NORMAL_USER = 'Normal user',
  STAFF = 'Staff',
  JUNIOR_MANAGER = 'Junior Manager',
  TEAM_MANAGER = 'Team Manager',
  CENTER_DIRECTOR = 'Center Director',
  REGIONAL_HEADQUATER_MANAGER = 'Regional Head Quater Manager',
}

export enum EPlatform {
  TITAN = 'titan',
  TOP_TRADING = 'top-trading',
}

export const ListSite = [
  { id: EPlatform.TITAN, name: 'Titan Trading' },
  { id: EPlatform.TOP_TRADING, name: 'Top Trading' },
];

export enum SelectApiType {
  DIVISION = 'DIVISION',
  TEAM = 'TEAM',
}

export const ObjPositionFilter = {
  label: 'Position',
  filterKey: 'position',
  items: [
    {
      name: 'All',
      key: '',
    },
    {
      name: 'Sale division manager',
      key: PositionSubAffiliateType.DIVISION_LEADER,
    },
    {
      name: 'Sale team leader',
      key: PositionSubAffiliateType.TEAM_LEADER,
    },
    {
      name: 'Sale staff',
      key: PositionSubAffiliateType.SUB_AFFILIATE,
    },
  ],
};

export const ObjStatusFilter = {
  label: 'Status',
  filterKey: 'status',
  items: [
    {
      name: 'All',
      key: '',
    },
    {
      name: 'Active',
      key: StatusKeys.Active,
    },
    {
      name: 'Disabled',
      key: StatusKeys.Inactive,
    },
  ],
};

export const ObjRangeDateTransactionFilter = {
  label: 'Transaction time',
  filterKey: ['startTime', 'endTime'],
  type: 'date',
};

export const ObjMemberGradeFilter = {
  label: 'Member grade',
  filterKey: 'packageType',
  items: [
    {
      name: 'All',
      key: '',
    },
    {
      name: 'Profit sharing member',
      key: EBotPackageType.PROFIT_SHARING,
    },
    {
      name: 'Basic member',
      key: EBotPackageType.BASIC,
    },
    {
      name: 'Advanced member',
      key: EBotPackageType.ADVANCE,
    },
    {
      name: 'Premium member',
      key: EBotPackageType.PREMIUM,
    },
    {
      name: 'VIP member',
      key: EBotPackageType.VIP,
    },
  ],
};

export enum BotShowType {
  PURCHASE_PACKAGE = 1,
  PROFIT_SHARING,
}

export enum ReferredUserChartPackageColor {
  BASIC = '#77D6AC',
  ADVANCE = '#5709DD',
  PREMIUM = '#86C4FF',
  VIP = '#858E9D',
  PROFIT_SHARING = '#FCD434',
}

export enum TransactionType {
  DEPOSIT = 1,
  WITHDRAW = 2,
  LOCK_IN_PROFIT_SHARING = 3,
  PURCHASE_PACKAGE = 4,
  COMMISSION = 5,
  PACKAGE_REFUND = 6,
  PROFIT_SHARING_REFUND = 7,
  COMMISSION_PAID = 8,
}

export const ObjCategoryFilter = {
  label: 'Transaction',
  filterKey: 'type',
  items: [
    {
      name: 'All',
      key: '',
    },
    {
      name: 'Withdrawal',
      key: TransactionType.WITHDRAW,
    },
    {
      name: 'Commission claim',
      key: TransactionType.COMMISSION,
    },
    {
      name: 'Commission paid',
      key: TransactionType.COMMISSION_PAID,
    },
    {
      name: 'Lock amount refunded',
      key: TransactionType.PROFIT_SHARING_REFUND,
    },
  ],
};

export enum CommissionReportChartColor {
  PURCHASE_PKG_OF_AFFILIATE = '#5709DD',
  PURCHASE_PKG_OF_COMMISSION_LEFT = '#AF86F7',
  PROFIT_SHARING_AFFILIATE = '#FCD434',
  PROFIT_SHARING_COMMISSION_LEFT = '#FCE89A',
}

export enum ENetwork {
  BEP20 = 1,
  ERC20,
  TRC20,
  BLAST,
}
