import { DollarIcon, ProfileIcon, UserMenuIcon, WalletIcon } from '@/assets';
import { ACCOUNT_PATH, COMMISSIONS_PATH, MY_REFERRALS_PATH, MY_WALLET_PATH } from '@/routes/constants';

export const menuSidebar = [
  {
    id: 1,
    is_group: true,
    title: '',
    items: [
      {
        id: 1,
        title: 'My referrals',
        path: MY_REFERRALS_PATH,
        icon: ProfileIcon,
      },

      {
        id: 2,
        title: 'Commissions',
        path: COMMISSIONS_PATH,
        icon: DollarIcon,
      },
      {
        id: 3,
        title: 'My wallet',
        path: MY_WALLET_PATH,
        icon: WalletIcon,
      },

      {
        id: 4,
        title: 'Account',
        path: ACCOUNT_PATH,
        icon: UserMenuIcon,
      },
    ],
  },
];

