import { ApiHelper } from '@/utils';

import { IListResponse, IQueryParam, IResponse } from '../common.services';
import {
  IDataBalance,
  IDataBalanceCoin,
  IDataBalanceStatistic,
  IDataTitanBalance,
  IDataBalanceStatistical,
  IDataBalanceTransactions,
} from './balance.interface';

class BalanceRoute {
  static readonly BALANCE = '/transactions';

  static readonly BALANCE_STATISTICAL = '/transactions/statistical';

  static readonly TITAN_BALANCE = '/titan-balances/affiliate-department/';

  static readonly TITAN_BALANCE_STATISTICAL = '/titan-balances/statistical';

  static readonly TITAN_BALANCE_TRANSACTION = '/titan-transactions';
}

export const getListBalance = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<IDataBalance>>(BalanceRoute.BALANCE, undefined, query);
};

export const getBalanceStatistic = async () => {
  return ApiHelper.get<IResponse<IDataBalanceStatistic>>(BalanceRoute.BALANCE_STATISTICAL);
};

export const getTitanBalance = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<IDataTitanBalance>>(BalanceRoute.TITAN_BALANCE, undefined, query);
};

export const getTitanBalanceByCoin = async (coinID: number) => {
  return ApiHelper.get<IResponse<IDataBalanceCoin>>(`${BalanceRoute.TITAN_BALANCE}${coinID}`);
};

export const getTitanBalanceStatistical = async () => {
  return ApiHelper.get<IResponse<IDataBalanceStatistical>>(BalanceRoute.TITAN_BALANCE_STATISTICAL);
};

export const getListTitanBalanceTransaction = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<IDataBalanceTransactions>>(
    BalanceRoute.TITAN_BALANCE_TRANSACTION,
    undefined,
    query,
  );
};
