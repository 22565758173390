import { CameraIcon, ImgAvatar } from '@/assets';
import { Button, Col, Row, Spin, notification } from 'antd';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import DialogChangePassword from './DialogChangePassword';
import { useToggleValue } from '@/hooks';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { userProfileState } from '@/store/user/selector';
import { uploadAvatar } from '@/services/settings/account/service';
import { requestUserProfile } from '@/store/user/actions';
import { LoadingOutlined } from '@ant-design/icons';

const Account: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [dialogChangePw, toggleDialogChangePw] = useToggleValue(false);
  const profile = useSelector(userProfileState);

  const referralCode = profile.customerReferral?.code || '';

  const downloadQRCode = () => {
    const canvas: any = document.querySelector('.account-referral-qrcode > div > canvas');
    console.log('canvas', canvas);
    if (!canvas) return;

    const pngFile = canvas.toDataURL('image/png');
    const downloadLink = document.createElement('a');
    downloadLink.download = 'qrcode';
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleChangeAvatar = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    setIsLoading(true);

    uploadAvatar(file)
      .then(() => {
        dispatch(requestUserProfile());
        notification.success({
          message: t('Change avatar successfully'),
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="titan-page referred-user-list-page">
      <span className="titan-page__title">My profile</span>
      <Row>
        <Col md={10} className="account-my-profile">
          <div className="account-box">
            <h2>My profile</h2>
            <hr />
            <div className="box-avatar">
              <img src={profile.avatar || ImgAvatar} alt="" />
              <Button type="link" className="btn-camera" onClick={() => fileInputRef.current?.click()}>
                <CameraIcon />
              </Button>
              {isLoading && (
                <Spin
                  className="loading-spin"
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 40,
                      }}
                      spin
                    />
                  }
                />
              )}
              <input
                onChange={handleChangeAvatar}
                multiple={false}
                ref={fileInputRef}
                type="file"
                hidden
                accept="image/*"
              />
            </div>
            <div className="account-info">
              <div className="account-info-row">
                <label>Email</label>
                <p>{profile.email}</p>
              </div>
              <div className="account-info-row">
                <label>Country</label>
                <p>{profile.country}</p>
              </div>
              <div className="account-info-row">
                <label>Commission rate</label>
                <p>{profile.commissionRate ? `${profile.commissionRate}%` : ''}</p>
              </div>
              <div className="account-info-row">
                <label>Division</label>
                <p>{profile.divisionName}</p>
              </div>
              <div className="account-info-row">
                <label>Team</label>
                <p>{profile.teamName}</p>
              </div>
              <div className="account-info-row">
                <label>Position </label>
                <p>{t(`PositionUser.${profile.position}`)}</p>
              </div>
            </div>
            <div className="account-actions">
              <Button type="link" onClick={toggleDialogChangePw}>
                {t('Change Password')}
              </Button>
            </div>
          </div>
        </Col>

        <Col md={14} className="account-referral">
          <div className="account-box">
            <h2>Referral</h2>
            <hr />
            <div className="account-referral-box">
              <div className="your-referral-code">
                Your referral code <span>{referralCode}</span>
              </div>

              <div className="copy-link">
                <CopyToClipboard
                  text={`${process.env.REACT_APP_DASHBOARD_URL}/register?referralId=${referralCode}`}
                  onCopy={() => {
                    notification.success({
                      message: 'Copied to clipboard!',
                      duration: 2,
                    });
                  }}
                >
                  <Button type="default" size="large" className="btn--outline">
                    {t('Copy referral link')}
                  </Button>
                </CopyToClipboard>
              </div>
            </div>

            <div className="account-referral-qrcode">
              <div>
                <QRCode value={referralCode} className="qr-code" bgColor="#252525" fgColor="white" size={207} />
                <br />
                <Button type="link" onClick={downloadQRCode}>
                  {t('Download QR image')}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {dialogChangePw && <DialogChangePassword openDialog={dialogChangePw} toggleDialog={toggleDialogChangePw} />}
    </div>
  );
};

export default Account;
