import {
  InitPagination,
  ObjRangeDateTransactionFilter,
  ObjStatusClaimRequestFilter,
  OptionPagination,
} from '@/constants';
import React, { useEffect, useState } from 'react';

import { TableData } from '@/components';
import Filter from '@/components/Filter';
import { getCommissionClaimRequestManagement } from '@/services/commission';
import { formatDateTime, formatNumber, getStatusClaimRequestByStatusKey, handleStringify } from '@/utils';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import moment from 'moment';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IFilter, ITransaction, TableParams } from './interface';

export const ClaimRequestManagement: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const parsedQuery = queryString.parse(history.location.search);
  const { pageSize, current, startTime, endTime, status } = parsedQuery;
  const [dataTable, setDataTable] = useState<ITransaction[]>([]);
  const [loadingGetDataTable, setLoadingGetDataTable] = useState(true);
  const [filterSelected, setFilterSelected] = useState<IFilter>({
    pageSize: pageSize ? Number(pageSize) : InitPagination.DEFAULT_PAGE_SIZE,
    current: current ? Number(current) : InitPagination.DEFAULT_PAGE,
    startTime: startTime ? String(startTime) : String(moment().subtract(6, 'days').unix()),
    endTime: endTime ? String(endTime) : String(moment().endOf('day').unix()),
    status: status ? String(status) : '',
  });
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: InitPagination.DEFAULT_PAGE,
      pageSize: InitPagination.DEFAULT_PAGE_SIZE,
    },
  });

  const getDataList = async () => {
    setLoadingGetDataTable(true);
    const queryParams = [
      { key: 'page', value: String(filterSelected.current) },
      { key: 'limit', value: String(filterSelected.pageSize) },
      { key: 'startTime', value: String(filterSelected.startTime) },
      { key: 'endTime', value: String(filterSelected.endTime) },
      { key: 'status', value: String(filterSelected.status) },
      { key: 'orderBy', value: 'createdAt' },
      { key: 'direction', value: 'DESC' },
    ];
    const response = await getCommissionClaimRequestManagement(queryParams);
    if (response && response.success) {
      const { meta } = response;
      const paginationResponse = {
        current: meta.currentPage,
        pageSize: filterSelected.pageSize,
      };
      setTableParams({
        ...tableParams,
        pagination: {
          ...paginationResponse,
          total: meta.total,
        },
      });
      const dataRewrite = response.data?.map((item: any) => {
        const newObj = { ...item, key: item.id };
        return newObj;
      });
      setDataTable(dataRewrite);
    }
    setLoadingGetDataTable(false);
  };

  useEffect(() => {
    getDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterSelected)]);

  const columns: ColumnsType<ITransaction> = [
    {
      title: t('Amount'),
      dataIndex: 'amount',
      render: (amount: string) => `$ ${formatNumber(parseFloat(amount))}`,
    },
    {
      title: t('Request time'),
      dataIndex: 'createdAt',
      render: (createdAt: string) => formatDateTime(createdAt, true),
    },
    {
      title: t('Reason'),
      dataIndex: 'reason',
      render: (reason: string) => `${reason ? reason : '-'}`,
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      render: (value: number) => {
        const item = getStatusClaimRequestByStatusKey(value);
        return (
          <span className={`titan-text`} style={{ color: item?.color }}>
            {item?.name}
          </span>
        );
      },
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig, filters?: any, sorter?: any) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    const ObjFilter = {
      ...filterSelected,
      pageSize: pagination.pageSize,
      current: pagination.current,
    };
    setFilterSelected(ObjFilter);
  };

  const handleItemFilter = (arr: { value?: string; key?: string }[]) => {
    if (arr.length) {
      const newObj = {
        ...filterSelected,
        pageSize: InitPagination.DEFAULT_PAGE_SIZE,
        current: InitPagination.DEFAULT_PAGE,
      } as any;
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        if (element.key) {
          newObj[element.key] = element.value;
        }
      }

      handleStringify(newObj, history);
      setFilterSelected(newObj);
      setTableParams({
        pagination: {
          current: InitPagination.DEFAULT_PAGE,
          pageSize: InitPagination.DEFAULT_PAGE_SIZE,
        },
      });
    } else {
      handleStringify(filterSelected, history);
    }
  };

  return (
    <div className="titan-page__content__main">
      <Filter
        options={[ObjStatusClaimRequestFilter, ObjRangeDateTransactionFilter]}
        action={handleItemFilter}
        filterSelected={filterSelected}
      />
      <TableData
        columns={columns}
        dataSource={dataTable}
        pagination={{
          ...tableParams.pagination,
          pageSizeOptions: OptionPagination,
          className: 'titan-pagination',
          showSizeChanger: true,
          showQuickJumper: true,
        }}
        loading={loadingGetDataTable}
        onChange={handleTableChange}
        isEmpty={!dataTable.length}
      />
    </div>
  );
};

export default ClaimRequestManagement;
