import React, { useCallback, useEffect, useState } from 'react';

import { TableData } from '@/components';
import Filter from '@/components/Filter';
import { InitPagination, ObjCategoryFilter, ObjRangeDateTransactionFilter, OptionPagination } from '@/constants';
import { formatNumber, formatTransactionHash, handleStringify } from '@/utils';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ITransaction, getListTransactions } from '@/services/settings/my-wallet';
import moment from 'moment';

export interface TableParams {
  pagination?: TablePaginationConfig;
  field?: string;
  order?: string;
  filters?: any;
}

export interface IFilter {
  pageSize?: number;
  current?: number;
  type?: number;
  startTime?: string;
  endTime?: string;
}

const Transactions: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const parsedQuery = queryString.parse(history.location.search);
  const { pageSize, current, type, startTime, endTime } = parsedQuery;

  const [dataTable, setDataTable] = useState<ITransaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [filterSelected, setFilterSelected] = useState<IFilter>({
    type: type ? Number(type) : undefined,
    startTime: startTime ? String(startTime) : String(moment().subtract(6, 'days').unix()),
    endTime: endTime ? String(endTime) : String(moment().endOf('day').unix()),
    pageSize: pageSize ? Number(pageSize) : InitPagination.DEFAULT_PAGE_SIZE,
    current: current ? Number(current) : InitPagination.DEFAULT_PAGE,
  });
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: current ? Number(current) : InitPagination.DEFAULT_PAGE,
      pageSize: pageSize ? Number(pageSize) : InitPagination.DEFAULT_PAGE_SIZE,
    },
  });

  useEffect(() => {
    getSubAffiliateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterSelected)]);

  const getSubAffiliateList = async () => {
    setLoading(true);
    const queryParams = [
      { key: 'page', value: String(filterSelected.current) },
      { key: 'limit', value: String(filterSelected.pageSize) },
      { key: 'type', value: String(filterSelected.type ?? '') },
      { key: 'startTime', value: String(filterSelected.startTime) },
      { key: 'endTime', value: String(filterSelected.endTime) },
      { key: 'orderBy', value: 'timestamp' },
      { key: 'direction', value: 'DESC' },
    ];
    const response = await getListTransactions(queryParams);
    if (response && response.success) {
      const { meta } = response;
      const paginationResponse = {
        current: meta.currentPage,
        pageSize: filterSelected.pageSize,
      };
      setTableParams({
        ...tableParams,
        pagination: {
          ...paginationResponse,
          total: meta.total,
        },
      });
      const dataRewrite = response.data?.map((item) => {
        const newObj = { ...item, key: item.id };
        return newObj;
      });
      setDataTable(dataRewrite);
    }
    setLoading(false);
  };

  const columns: ColumnsType<ITransaction> = [
    {
      title: t('Transaction'),
      dataIndex: 'type',
      width: 200,
      render: (value) => {
        return <span className="nowrap">{value && t(`TransactionType.${value}`)}</span>;
      },
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      width: 200,
      render: (value, record) => <span className="nowrap">{formatNumber(value, record?.coin?.symbol)}</span>,
    },
    {
      title: t('Time'),
      dataIndex: 'timestamp',
      width: 200,
      render: (value) => <div className="nowrap">{moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}</div>,
    },
    {
      title: t('Sub-affiliate'),
      dataIndex: 'customer',
      width: 250,
      render: (customer: any) => customer.email,
    },
    {
      title: t('Transaction hash'),
      dataIndex: 'transactionHash',
      width: 400,
      render: (value) => formatTransactionHash(value),
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig, filters?: any, sorter?: any) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    const ObjFilter = {
      ...filterSelected,
      pageSize: pagination.pageSize,
      current: pagination.current,
    };
    setFilterSelected(ObjFilter);
  };

  const handleItemFilter = useCallback(
    (arr: { value?: string; key?: string }[]) => {
      if (arr.length) {
        const newObj = {
          ...filterSelected,
          pageSize: InitPagination.DEFAULT_PAGE_SIZE,
          current: InitPagination.DEFAULT_PAGE,
        } as any;
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          if (element.key) {
            newObj[element.key] = element.value;
          }
        }
        handleStringify(newObj, history);
        setFilterSelected(newObj);
        setTableParams({
          pagination: {
            current: InitPagination.DEFAULT_PAGE,
            pageSize: InitPagination.DEFAULT_PAGE_SIZE,
          },
        });
      } else {
        handleStringify(filterSelected, history);
      }
    },
    [filterSelected, history],
  );

  const renderFilter = useCallback(() => {
    const filterItems = [ObjCategoryFilter, ObjRangeDateTransactionFilter];
    return <Filter options={filterItems} action={handleItemFilter} filterSelected={filterSelected} />;
  }, [filterSelected, handleItemFilter]);

  return (
    <div>
      <div className="titan-page__box-title">
        <div style={{ display: 'flex' }}>{renderFilter()}</div>
      </div>

      <div className="titan-page__content__main" style={{ marginTop: 20 }}>
        <TableData
          columns={columns}
          dataSource={dataTable}
          pagination={{
            ...tableParams.pagination,
            pageSizeOptions: OptionPagination,
            className: 'titan-pagination',
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          loading={loading}
          onChange={handleTableChange}
          isEmpty={!dataTable.length}
        />
      </div>
    </div>
  );
};

export default Transactions;
