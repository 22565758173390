import { ApiHelper } from '@/utils';

import { IMyReferral } from './my-referrals.interface';
import { IListResponse, IQueryParam } from '../common.services';

class MyReferralsServiceRoute {
  static readonly LIST_MY_REFERRALS = '/sub-affiliate/users/my-referrals';
}

export const getListMyReferrals = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<IMyReferral>>(MyReferralsServiceRoute.LIST_MY_REFERRALS, undefined, query);
};
