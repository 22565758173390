import React from 'react';

import { LoginLayout, MainLayout } from '../layouts';
import { ACCOUNT_PATH, COMMISSIONS_PATH, MY_REFERRALS_PATH, MY_WALLET_PATH } from './constants';
import MyReferrals from '@/pages/my-referrals';
import MyWallet from '@/pages/my-wallet';
import Account from '@/pages/account';
import CommissionClaim from '@/pages/commissions';

//no auth component
const Login = React.lazy(() => import('@/pages/sign-in'));
const ForgotPassword = React.lazy(() => import('@/pages/forgot-password'));
const ResetPassword = React.lazy(() => import('@/pages/reset-password'));
const ConfirmCode = React.lazy(() => import('@/pages/confirm-code'));
//

export interface IRoute {
  Component: ((props: any) => JSX.Element) | React.FC<any>;
  Layout: ((props: any) => JSX.Element) | React.FC<any>;
  Protected: boolean;
  isHelpcenter?: boolean;
  path?: string | string[];
  routePath?: string;
  from?: string;
  to?: string;
  exact?: boolean;
}

const noAuthRoute: IRoute[] = [
  {
    Component: Login,
    Layout: LoginLayout,
    exact: true,
    path: '/sign-in',
    routePath: '/sign-in',
    Protected: false,
  },
  {
    Component: ForgotPassword,
    Layout: LoginLayout,
    exact: true,
    path: '/forgot-password',
    routePath: '/forgot-password',
    Protected: false,
  },
  {
    Component: ConfirmCode,
    Layout: LoginLayout,
    exact: true,
    path: '/confirm-code',
    routePath: '/confirm-code',
    Protected: false,
  },

  {
    Component: ResetPassword,
    Layout: LoginLayout,
    exact: true,
    path: '/reset-password',
    routePath: '/reset-password',
    Protected: false,
  },
];

const authRouter: IRoute[] = [
  {
    Component: MyReferrals,
    Layout: MainLayout,
    exact: true,
    path: [MY_REFERRALS_PATH],
    routePath: MY_REFERRALS_PATH,
    Protected: true,
  },
  {
    Component: CommissionClaim,
    Layout: MainLayout,
    exact: true,
    path: [COMMISSIONS_PATH],
    routePath: COMMISSIONS_PATH,
    Protected: true,
  },
  {
    Component: MyWallet,
    Layout: MainLayout,
    exact: true,
    path: [MY_WALLET_PATH],
    routePath: MY_WALLET_PATH,
    Protected: true,
  },
  {
    Component: Account,
    Layout: MainLayout,
    exact: true,
    path: [ACCOUNT_PATH],
    routePath: ACCOUNT_PATH,
    Protected: true,
  },
];

export const routes = [...noAuthRoute, ...authRouter];
