import { ApiHelper } from '@/utils';

import { IListResponse, IListResponseObj, IQueryParam, IResponse } from '../common.services';
import {
  ICommissionReport,
  ICommissionClaimStatistic,
  ICommissionClaimTransactions,
  ICommissionRequest,
} from './commission.interface';

class CommissionRoute {
  static readonly COMMISSION_REPORT = '/commission-request/commission-report';

  static readonly COMMISSION_CLAIM_STATISTIC = '/commission-request/statistic-commission';

  static readonly COMMISSION_CLAIM_TRANSACTIONS = '/commission-request/history-transactions';

  static readonly COMMISSION_CLAIM_MY_CLAIM = '/commission-request/my-claim-requests';

  static readonly POST_COMMISSION_CLAIM = '/users/affiliate-department/claim-commission';

  static readonly COMMISSION_REQUEST = '/commission-request';
}
export const getCommissionReport = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponseObj<ICommissionReport>>(CommissionRoute.COMMISSION_REPORT, undefined, query);
};
export const getCommissionClaimStatistic = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponseObj<ICommissionClaimStatistic>>(
    CommissionRoute.COMMISSION_CLAIM_STATISTIC,
    undefined,
    query,
  );
};
export const getCommissionClaimTransactions = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<ICommissionClaimTransactions>>(
    CommissionRoute.COMMISSION_CLAIM_TRANSACTIONS,
    undefined,
    query,
  );
};
export const getCommissionClaimRequestManagement = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<ICommissionClaimTransactions>>(
    CommissionRoute.COMMISSION_CLAIM_MY_CLAIM,
    undefined,
    query,
  );
};

export const postCommissionClaim = async () => {
  return ApiHelper.post<IResponse<any>, {}>(CommissionRoute.POST_COMMISSION_CLAIM);
};

export const getCommissionRequest = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<ICommissionRequest>>(CommissionRoute.COMMISSION_REQUEST, undefined, query);
};
