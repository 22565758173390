import React, { useEffect, useState } from 'react';

import { Layout, Menu } from 'antd';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { LogoutIcon } from '@/assets';
import { useCheckDevice } from '@/hooks';
import { UserLogout } from '@/store/auth/actions';

import { menuSidebar } from './constants';
import { userProfile } from '@/store/user/saga';
import { SignInImg } from '@/assets';

interface IPropsSidebars {
  collapsed: boolean;
  setCollapsed: () => void;
}

export const Sidebars: React.FC<IPropsSidebars> = ({ collapsed, setCollapsed }) => {
  const { Sider } = Layout;
  const history = useHistory();
  const role = userProfile();
  let resolved = useRouteMatch();

  const pathNameIsThird = history?.location.pathname?.split('/').reverse().slice(1).reverse().join('/');
  const pathName = resolved.path.split('/:');
  const dispatch = useDispatch();
  const [sidebarRole, setSidebarRole] = useState<any>([]);
  const [selectedMenu, setSelectedMenu] = useState<string | undefined>('');
  const { isMobile } = useCheckDevice();

  useEffect(() => {
    const sidebar = menuSidebar;
    setSidebarRole(sidebar);
    for (let index = 0; index < sidebar?.length; index++) {
      const element = sidebar[index];
      const pathActive = element.items.find(
        (itemChild) => itemChild?.path === pathName[0] || itemChild?.path === pathNameIsThird,
      );
      if (pathActive && pathActive.id) {
        return setSelectedMenu(`${element.id}_${pathActive.id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName, role]);

  const handleClickMenuMobile = () => {
    if (isMobile) {
      setCollapsed();
    }
  };

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className={`titan-sidebar ${collapsed ? 'active' : ''}`}
      width={240}
    >
      <div>
        <div className="titan-sidebar__top">
          <div className="logo">
            <img src={SignInImg} alt="" />
          </div>
          <div className="avatar">H</div>
        </div>
        <div className="titan-sidebar__divider" />
        <Menu mode="inline" selectedKeys={[String(selectedMenu)]} className="titan-menu">
          {sidebarRole?.map((item: any) => {
            if (item.is_group) {
              return (
                <div key={`group_${item.id}`}>
                  <Menu selectedKeys={[String(selectedMenu)]} className="titan-menu__group titan-menu">
                    <div key={`${item.id}__group-title`} className="titan-menu__group__title">
                      {item.title}
                    </div>
                    {item?.items?.map((menuChild: any) => {
                      return (
                        <Menu.Item
                          key={`${item.id}_${menuChild.id}`}
                          icon={<menuChild.icon />}
                          onClick={handleClickMenuMobile}
                        >
                          <Link to={menuChild.path}>{menuChild.title}</Link>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                  <div className="titan-sidebar__divider" />
                </div>
              );
            }

            const itemIcon = item?.icon ? <item.icon /> : <></>;
            return (
              <div key={item.id}>
                <Menu.Item key={item.id} icon={itemIcon} onClick={handleClickMenuMobile}>
                  <Link to={item.path ?? ''}>{item.title}</Link>
                </Menu.Item>
                <div className="titan-sidebar__divider" />
              </div>
            );
          })}
        </Menu>
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={['0']}
        className="titan-menu titan-menu--bottom"
        onClick={() => dispatch(UserLogout())}
      >
        <Menu.Item key="100" icon={<LogoutIcon />}>
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
