import { CloseIcon, FilterIcon, FilterWhiteIcon } from '@/assets';
import { Button, Drawer, Radio, RadioChangeEvent } from 'antd';
import React, { useCallback, useState } from 'react';
import RangePicker from './RangePicker';

interface IItem {
  name: string;
  key: string | number;
}
interface IOptionItem {
  label: string;
  filterKey: string | string[];
  items?: IItem[];
  type?: string | 'date';
}
interface IFilter {
  action: (arr: { value: string; key: string }[]) => void;
  filterSelected: any;
  options: IOptionItem[];
}
const Filter: React.FC<IFilter> = ({ action, filterSelected, options }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSelect = (e: RadioChangeEvent, key: string) => {
    const { value } = e.target;
    action([{ value, key }]);
  };

  const handleNewSelect = (arr: { value: string; key: string }[]) => {
    action(arr);
  };

  const renderTotalFilter = useCallback(() => {
    const checklist = options.map((i) => i.filterKey);
    let total = 0;
    for (let index = 0; index < checklist.length; index++) {
      const element = checklist[index];
      if (element.length && typeof element === 'object') {
        let totalHasValue = 0;
        for (let eIndex = 0; eIndex < element.length; eIndex++) {
          const value = element[eIndex];
          if (filterSelected[value]) {
            totalHasValue = totalHasValue + 1;
          }
        }
        if (totalHasValue === element.length) {
          total = total + 1;
        }
      } else {
        if (filterSelected[element as string]) {
          total = total + 1;
        }
      }
    }
    if (total) {
      return `(${total})`;
    }
    return '';
  }, [filterSelected, options]);

  return (
    <div>
      <Button className="btn-filter" size="large" type="default" onClick={showDrawer}>
        <FilterIcon />
        {`Filter ${renderTotalFilter()}`}
      </Button>
      <Drawer
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FilterWhiteIcon />
            <span style={{ marginLeft: 5 }}>{`Filter ${renderTotalFilter()}`}</span>
          </div>
        }
        placement="right"
        onClose={onClose}
        open={open}
        closeIcon={<CloseIcon />}
      >
        {options.map((data: any, dataIdx: number) => {
          if (data.type === 'date') {
            return (
              <div key={String(dataIdx + 1)}>
                <div className="titan-filter-label">{data.label}</div>
                <RangePicker
                  callback={handleNewSelect}
                  startTime={filterSelected.startTime}
                  endTime={filterSelected.endTime}
                />
              </div>
            );
          }
          return (
            <div key={String(dataIdx + 1)}>
              <div className="titan-filter-label">{data.label}</div>
              <div>
                {data.items.map((item: any, itemIdx: number) => {
                  return (
                    <div key={String(itemIdx + 1)} style={{ margin: '10px 0px' }}>
                      <Radio
                        value={item.key}
                        checked={filterSelected[data.filterKey] === item.key}
                        onChange={(e) => handleSelect(e, data.filterKey)}
                      >
                        {item.name}
                      </Radio>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Drawer>
    </div>
  );
};

export default Filter;
