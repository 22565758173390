import { ApiHelper } from '@/utils';

import { IDataWidthDrawRequest, IDataWithdrawFee, IParamActionWithdrawRequest } from './withdraw.interface';
import { IListResponse, IQueryParam, IResponse } from '../common.services';
import { IParamWithdraw } from '../api-connection';

class WithdrawRoute {
  static readonly WITHDRAW = '/withdraw-requests';

  static readonly WITHDRAW_REQUEST_APPROVED = '/withdraw-requests/approve';

  static readonly WITHDRAW_REQUEST_REJECT = '/withdraw-requests/reject';

  static readonly WITHDRAW_FEE = '/withdraw-requests/fee';

  static readonly WITHDRAW_TO_WALLET = '/withdraw-requests/affiliate';
}

export const getListWithdrawRequest = async (params?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<any>>(WithdrawRoute.WITHDRAW, undefined, params);
};

export const approveWithdrawRequest = async (payload?: IParamActionWithdrawRequest) => {
  return ApiHelper.put<IResponse<IDataWidthDrawRequest>, any>(WithdrawRoute.WITHDRAW_REQUEST_APPROVED, payload);
};

export const rejectWithdrawRequest = async (payload?: IParamActionWithdrawRequest) => {
  return ApiHelper.put<IResponse<IDataWidthDrawRequest>, any>(WithdrawRoute.WITHDRAW_REQUEST_REJECT, payload);
};

export const withdrawToWallet = async (params?: any) => {
  return ApiHelper.post<IResponse<any>, IParamWithdraw>(WithdrawRoute.WITHDRAW_TO_WALLET, params);
};

export const getFeeWithdraw = async () => {
  return ApiHelper.get<IResponse<IDataWithdrawFee>>(WithdrawRoute.WITHDRAW_FEE);
};
