import { BusdtIcon, BnbIcon, TetherIcon, EthIcon, TrxIcon } from '@/assets';

export const listToken = [
  {
    id: 1,
    name: 'USDT',
    value: 'USDT',
    icon: TetherIcon,
  },
  {
    id: 2,
    name: 'BUSDT',
    value: 'BUSDT',
    icon: BusdtIcon,
  },
  {
    id: 3,
    name: 'BNB',
    value: 'BNB',
    icon: BnbIcon,
  },
  {
    id: 4,
    name: 'TRX',
    value: 'TRX',
    icon: TrxIcon,
  },
  {
    id: 5,
    name: 'ETH',
    value: 'ETH',
    icon: EthIcon,
  },
];

export const netWorks = [
  {
    id: 1,
    name: 'BEP20',
    value: 1,
  },
  {
    id: 2,
    name: 'ERC20',
    value: 2,
  },
  {
    id: 3,
    name: 'TRC20 (only USDT)',
    value: 3,
  },
  {
    id: 4,
    name: 'BLAST',
    value: 4,
  }
];
