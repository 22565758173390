import React, { useCallback, useEffect, useState } from 'react';

import { SearchIcon } from '@/assets';
import { TableData } from '@/components';
import { ENetwork, InitPagination, MoneyDefault, OptionPagination } from '@/constants';
import { formatNumber, handleStringify } from '@/utils';
import { Input } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MyWalletBalance from './MyWalletBalance';
import { getListAssets } from '@/services/settings/my-wallet/my-wallet.service';
import { IAsset } from '@/services/settings/my-wallet';

export interface TableParams {
  pagination?: TablePaginationConfig;
  field?: string;
  order?: string;
  filters?: any;
}

export interface IFilter {
  pageSize?: number;
  current?: number;
  search?: string;
}

const TopTradingBotWallets: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const parsedQuery = queryString.parse(history.location.search);
  const { pageSize, current, search } = parsedQuery;

  const [dataTable, setDataTable] = useState<IAsset[]>([]);
  const [valueSearch, setValueSearch] = useState<string>(search ? String(search) : '');
  const [loading, setLoading] = useState(false);
  const [filterSelected, setFilterSelected] = useState<IFilter>({
    search: search ? String(search) : '',

    pageSize: pageSize ? Number(pageSize) : InitPagination.DEFAULT_PAGE_SIZE,
    current: current ? Number(current) : InitPagination.DEFAULT_PAGE,
  });
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: current ? Number(current) : InitPagination.DEFAULT_PAGE,
      pageSize: pageSize ? Number(pageSize) : InitPagination.DEFAULT_PAGE_SIZE,
    },
  });

  useEffect(() => {
    getAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterSelected)]);

  const getAssets = async () => {
    setLoading(true);
    const queryParams = [
      { key: 'page', value: String(filterSelected.current) },
      { key: 'limit', value: String(filterSelected.pageSize) },
      { key: 'search', value: String(filterSelected.search ?? '') },
      { key: 'orderBy', value: 'updatedAt' },
      { key: 'direction', value: 'DESC' },
    ];
    const response = await getListAssets(queryParams);
    if (response && response.success) {
      const { meta } = response;
      const paginationResponse = {
        current: meta.currentPage,
        pageSize: filterSelected.pageSize,
      };
      setTableParams({
        ...tableParams,
        pagination: {
          ...paginationResponse,
          total: meta.total,
        },
      });
      const dataRewrite = response.data?.map((item) => {
        const newObj = { ...item, key: item.id };
        return newObj;
      });
      setDataTable(dataRewrite);
    }
    setLoading(false);
  };

  const columns: ColumnsType<IAsset> = [
    {
      title: t('Assets'),
      dataIndex: 'symbol',
    },
    {
      title: t('Amount'),
      dataIndex: 'balance',
      render: (value, record) => <span className="nowrap">{formatNumber(Number(value), record?.symbol, true)}</span>,
    },
    {
      title: t('Value'),
      dataIndex: 'value',
      sorter: true,
      render: (value) => (
        <span className="nowrap">{formatNumber(Number(value), MoneyDefault.USDT, true, undefined, 6)} </span>
      ),
    },
    {
      title: t('Network'),
      dataIndex: 'network',
      render: (value) => <span className="nowrap">{ENetwork[value]}</span>,
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig, filters?: any, sorter?: any) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    const ObjFilter = {
      ...filterSelected,
      pageSize: pagination.pageSize,
      current: pagination.current,
    };
    setFilterSelected(ObjFilter);
  };

  const handleItemFilter = useCallback(
    (e?: string, key?: string) => {
      if (key) {
        const newObj = {
          ...filterSelected,
          pageSize: InitPagination.DEFAULT_PAGE_SIZE,
          current: InitPagination.DEFAULT_PAGE,
          [key]: e,
        };
        handleStringify(newObj, history);
        setFilterSelected(newObj);
        setTableParams({
          pagination: {
            current: InitPagination.DEFAULT_PAGE,
            pageSize: InitPagination.DEFAULT_PAGE_SIZE,
          },
        });
      } else {
        handleStringify(filterSelected, history);
      }
    },
    [filterSelected, history],
  );

  const onChangeSearch = (e: any) => {
    if (!e.target.value) {
      handleItemFilter(e.target.value, 'search');
    }
    setValueSearch(e.target.value);
  };

  return (
    <div>
      <MyWalletBalance refetchAssets={getAssets} />

      <div className="titan-page__box-title">
        <div style={{ display: 'flex' }}>
          <Input
            allowClear
            placeholder={t('placeholder.Search by assets') || ''}
            onPressEnter={(e: any) => handleItemFilter(e.target.value, 'search')}
            prefix={<SearchIcon />}
            className="titan-input--search"
            onChange={onChangeSearch}
            value={valueSearch ?? ''}
            size="large"
            style={{ marginRight: 10, minWidth: 300 }}
          />
        </div>
      </div>

      <div className="titan-page__content__main">
        <TableData
          columns={columns}
          dataSource={dataTable}
          pagination={{
            ...tableParams.pagination,
            pageSizeOptions: OptionPagination,
            className: 'titan-pagination',
            showSizeChanger: true,
            showQuickJumper: true,
          }}
          loading={loading}
          onChange={handleTableChange}
          isEmpty={!dataTable.length}
        />
      </div>
    </div>
  );
};

export default TopTradingBotWallets;
