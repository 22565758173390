import React from 'react';

import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface IMixTitle {
  title?: string | null;
  isTooltip?: boolean;
  tooltipValue?: string | null;
  titleWeight?: number;
}

export const MixTitle: React.FC<IMixTitle> = ({ title, isTooltip, tooltipValue, titleWeight }) => {
  return (
    <span className="form-label" style={{ fontWeight: titleWeight ? titleWeight : 600 }}>
      {title}
      {isTooltip && (
        <Tooltip title={tooltipValue ?? ''} className="form-label__tooltip">
          <InfoCircleFilled />
        </Tooltip>
      )}
    </span>
  );
};
