import React, { useEffect, useState } from 'react';

import { SearchIcon } from '@/assets';
import { TableData } from '@/components';
import { InitPagination, OptionPagination } from '@/constants';
import { Button, Input, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { DialogReferralQRCode } from './DialogReferralQRCode';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { userProfileState } from '@/store/user/selector';
import { useHistory } from 'react-router-dom';
import { formatDateTime, handleStringify } from '@/utils';
import { TablePaginationConfig } from 'antd/es/table';
import queryString from 'query-string';
import { IMyReferral, getListMyReferrals } from '@/services/my-referrals';
import { ColumnsType } from 'antd/lib/table';

export interface TableParams {
  pagination?: TablePaginationConfig;
  field?: string;
  order?: string;
  filters?: any;
}

export interface IFilter {
  pageSize?: number;
  current?: number;
  searchText?: string;
}

const MyReferrals: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const parsedQuery = queryString.parse(history.location.search);
  const { pageSize, current, searchText } = parsedQuery;

  const [isShowReferral, setIsShowReferral] = useState(false);

  const profile = useSelector(userProfileState);
  const referralCode = profile.customerReferral?.code || '';

  const [dataTable, setDataTable] = useState<IMyReferral[]>([]);
  const [valueSearch, setValueSearch] = useState<string>(searchText ? String(searchText) : '');
  const [loading, setLoading] = useState(true);
  const [filterSelected, setFilterSelected] = useState<IFilter>({
    searchText: searchText ? String(searchText) : '',
    pageSize: pageSize ? Number(pageSize) : InitPagination.DEFAULT_PAGE_SIZE,
    current: current ? Number(current) : InitPagination.DEFAULT_PAGE,
  });
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: current ? Number(current) : InitPagination.DEFAULT_PAGE,
      pageSize: pageSize ? Number(pageSize) : InitPagination.DEFAULT_PAGE_SIZE,
    },
  });

  const getUserList = async () => {
    setLoading(true);
    const queryParams = [
      { key: 'page', value: String(filterSelected.current) },
      { key: 'limit', value: String(filterSelected.pageSize) },
      { key: 'searchText', value: String(filterSelected.searchText ?? '') },
      { key: 'orderBy', value: 'updatedAt' },
      { key: 'direction', value: 'DESC' },
    ];

    const response = await getListMyReferrals(queryParams);
    if (response && response.success) {
      const { meta } = response;
      const paginationResponse = {
        current: meta.currentPage,
        pageSize: filterSelected.pageSize,
      };
      setTableParams({
        ...tableParams,
        pagination: {
          ...paginationResponse,
          total: meta.total,
        },
      });
      const dataRewrite = response.data?.map((item) => {
        const newObj = { ...item, key: item.id };
        return newObj;
      });
      setDataTable(dataRewrite);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterSelected)]);

  const columns: ColumnsType<IMyReferral> = [
    {
      title: t('Email address'),
      dataIndex: 'email',
    },
    {
      title: t('Country'),
      dataIndex: 'country',
    },
    {
      title: t('Bot package'),
      dataIndex: 'botPackageName',
      render: (value: string) => <>{value}</>,
    },
    {
      title: t('No. of Profit sharing bots'),
      dataIndex: 'totalUseProfitSharing',
      render: (value: string) => (value !== null ? value : '-'),
    },
    {
      title: t('Date'),
      dataIndex: 'createdAt',
      render: (createdAt: string) => formatDateTime(createdAt),
    },
  ];

  const handleTableChange = (pagination: TablePaginationConfig, filters?: any, sorter?: any) => {
    setTableParams({
      pagination,
      ...sorter,
    });
    const ObjFilter = {
      ...filterSelected,
      pageSize: pagination.pageSize,
      current: pagination.current,
    };
    setFilterSelected(ObjFilter);
  };

  const handleItemFilter = (arr: { value?: string; key?: string }[]) => {
    if (arr.length) {
      const newObj = {
        ...filterSelected,
        pageSize: InitPagination.DEFAULT_PAGE_SIZE,
        current: InitPagination.DEFAULT_PAGE,
      } as any;
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        if (element.key) {
          newObj[element.key] = element.value;
        }
      }
      handleStringify(newObj, history);
      setFilterSelected(newObj);
      setTableParams({
        pagination: {
          current: InitPagination.DEFAULT_PAGE,
          pageSize: InitPagination.DEFAULT_PAGE_SIZE,
        },
      });
    } else {
      handleStringify(filterSelected, history);
    }
  };

  const onChangeSearch = (e: any) => {
    if (!e.target.value) {
      handleItemFilter([{ value: e.target.value, key: 'searchText' }]);
    }
    setValueSearch(e.target.value);
  };

  return (
    <div className="titan-page referred-user-list-page">
      <span className="titan-page__title">My referrals</span>

      <div className="titan-page__box-title">
        <div>
          <Input
            allowClear
            placeholder={t('Search by team name') || ''}
            onPressEnter={(e: any) => handleItemFilter([{ value: e.target.value, key: 'searchText' }])}
            prefix={<SearchIcon />}
            className="titan-input--search"
            onChange={onChangeSearch}
            value={valueSearch ?? ''}
            size="large"
          />
        </div>

        <div className="titan-page__actions">
          <div className="your-referral-code">
            Your referral code <span>{referralCode}</span>
          </div>

          <div className="copy-link">
            <CopyToClipboard
              text={`${process.env.REACT_APP_DASHBOARD_URL}/register?referralId=${referralCode}`}
              onCopy={() => {
                notification.success({
                  message: 'Copied to clipboard!',
                  duration: 2,
                });
              }}
            >
              <Button type="default" size="large" className="btn--outline">
                {t('Copy referral link')}
              </Button>
            </CopyToClipboard>
          </div>
          <Button type="default" size="large" className="btn--outline" onClick={() => setIsShowReferral(true)}>
            {t('Show QR code')}
          </Button>
        </div>
      </div>

      <div className="titan-page__content__main">
        {!loading && (
          <TableData
            columns={columns}
            dataSource={dataTable}
            pagination={{
              ...tableParams.pagination,
              pageSizeOptions: OptionPagination,
              className: 'titan-pagination',
              showSizeChanger: true,
              showQuickJumper: true,
            }}
            loading={loading}
            onChange={handleTableChange}
            isEmpty={!dataTable.length}
          />
        )}
      </div>

      <DialogReferralQRCode
        isOpen={isShowReferral}
        referralCode={referralCode}
        onClose={() => setIsShowReferral(false)}
      />
    </div>
  );
};

export default MyReferrals;
