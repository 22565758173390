import { ApiHelper } from '@/utils';

import { IListResponse, IQueryParam, IResponse } from '../common.services';
import { IDataCoins, IDataMarket } from './coins.interface';

class CoinsRoute {
  static readonly COINS = '/coins';

  static readonly MARKET_COIN = '/coins/market-data';
}

export const getListCoin = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<IDataCoins>>(CoinsRoute.COINS, undefined, query);
};

export const getListCoinMarket = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IResponse<IDataMarket>>(CoinsRoute.MARKET_COIN, undefined, query);
};
