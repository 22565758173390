import React, { useState, useEffect } from 'react';

import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Form, Row, Col, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import TronWeb from 'tronweb';
import web3Utils from 'web3-utils';

import { ArrowDownIcon2, SuccessIcon } from '@/assets';
import { Dialog, InputCurrency, InputNormal, MixTitle } from '@/components';
import { useToggleValue } from '@/hooks';
import { getTitanBalanceByCoin } from '@/services/balance';
import { getListCoin, IDataCoins } from '@/services/coins';
import { withdrawToWallet } from '@/services/withdraw';
import { formatCurrencyToNumber, formatNumber } from '@/utils';

import { netWorks } from './constants';
window.Buffer = window.Buffer || require('buffer').Buffer;

interface IDialogWithdraw {
  openDialog?: boolean;
  setOpenDialog: () => void;
  onReloadBalance: () => void;
}

interface IFormWithdraw {
  network: number;
  token: number;
  withdrawAmount: string;
  walletAddress: string;
}

export const DialogWithdraw: React.FC<IDialogWithdraw> = ({ openDialog = false, setOpenDialog, onReloadBalance }) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [validateForm, setValidateForm] = useState<Map<string, boolean>>(new Map());
  const [dialogSuccess, toggleDialogSuccess] = useToggleValue(false);
  const [hiddenDialog, toggleHiddenDialog] = useToggleValue(false);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const [coins, setCoins] = useState<IDataCoins[]>([]);
  const [networkSelected, setNetWorkSelected] = useState<number>();
  const [coinSelected, setCoinSelected] = useState<number>();
  const [coinChosen, setCoinChosen] = useState<IDataCoins>();
  const [balanceCoin, setBalanceCoin] = useState<number>(0);

  const netWorkChange = Form.useWatch('network', form);
  const coinChange = Form.useWatch('token', form);

  const tronWeb = new TronWeb({
    fullHost: 'https://api.trongrid.io',
  });
  const handleWithdraw = async (values: IFormWithdraw) => {
    setLoadingWithdraw(true);
    const payload = {
      network: values.network,
      coinId: values.token,
      toAddress: values.walletAddress,
      amount: String(formatCurrencyToNumber(values.withdrawAmount)),
    };
    const response = await withdrawToWallet(payload);
    if (response.success) {
      toggleDialogSuccess();
      toggleHiddenDialog();
      setLoadingWithdraw(false);
      onReloadBalance();
    } else {
      setLoadingWithdraw(false);
    }
  };

  const handleSuccessWithdraw = () => {
    setOpenDialog();
    toggleDialogSuccess();
    toggleHiddenDialog();
  };

  const getMyBalanceByCoin = async (coin: number) => {
    const response = await getTitanBalanceByCoin(coin);
    if (response.success) {
      setBalanceCoin(Number(response.data.balance));
    }
  };

  const getCoins = async (netWorkSelect: number) => {
    console.log('getCoins', netWorkSelect);
    const payload = [{ key: 'network', value: netWorkSelect }];
    const response = await getListCoin(payload);
    if (response.success) {
      const dataResponse = response.data ?? [];
      setCoins(dataResponse);
      setCoinSelected(dataResponse?.[0]?.id);

      if (!dataResponse?.length) {
        setBalanceCoin(0);
      }
    }
  };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinSelected, networkSelected]);

  useEffect(() => {
    setCoinSelected(coinChange);
    setValidateForm(new Map());
  }, [coinChange]);

  useEffect(() => {
    if (netWorkChange) {
      getCoins(netWorkChange);
      setNetWorkSelected(netWorkChange);
      setValidateForm(new Map());
    } else {
      setNetWorkSelected(netWorks?.[0].value);
    }
  }, [netWorkChange]);

  useEffect(() => {
    if (coinSelected) {
      getMyBalanceByCoin(coinSelected);
      const coin = coins?.find((item) => item.id === coinSelected);
      setCoinChosen(coin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinSelected]);

  const handleValidate = (nameInput: string, err: boolean) => {
    const newMapData = new Map<string, boolean>();
    newMapData.set(nameInput, err);
    const merged = new Map([...validateForm, ...newMapData]);
    setValidateForm(merged);
  };

  const onSelectMaxAmount = () => {
    form.setFieldValue('withdrawAmount', balanceCoin.toString());
  };

  return (
    <>
      {openDialog && !hiddenDialog && (
        <Dialog
          isOpen={openDialog}
          handleClose={setOpenDialog}
          className="titan-modal titan-modal--form-mobile"
          wrapClassName="titan-modal__wrap-500"
          isCloseButton
        >
          <div className="titan-modal__content center">
            <div className="title "> {t('Withdraw')}</div>
            <Form
              name="form-withdraw"
              initialValues={{ network: networkSelected, token: coinSelected }}
              onFinish={handleWithdraw}
              onFinishFailed={() => null}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
              className="titan-form"
              form={form}
            >
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="network" label={t('Network') || ''}>
                    <Select
                      placeholder={t('Select network')}
                      className="titan-select"
                      suffixIcon={<ArrowDownIcon2 />}
                      popupClassName={'titan-select__popup'}
                    >
                      {netWorks?.map((item) => (
                        <Option value={item.value} key={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item name="token" label={t('Token') || ''}>
                    <Select
                      placeholder={t('Select token')}
                      className="titan-select"
                      suffixIcon={<ArrowDownIcon2 />}
                      popupClassName={'titan-select__popup'}
                    >
                      {coins?.map((item) => (
                        <Option value={item.id} key={item.id} className="option_icon">
                          <img
                            src={`${process.env.REACT_APP_S3_URL}/${item?.imageUrl}`}
                            className="icon"
                            alt="Logo coin"
                          />
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                className="form-item-label-space-between"
                label={
                  <div className="form-item-label-space-between__detail">
                    <MixTitle title={`${t('Withdraw amount')}`} />
                    <div className="item">
                      <span className="label">
                        Avbl:{' '}
                        <Tooltip
                          title={t(
                            'This is the amount you locked in all your copytrade bots. The rest of the amount after profit sharing will be paid back if you disconnect the bots.',
                          )}
                        >
                          <InfoCircleFilled />
                        </Tooltip>
                      </span>
                      <span className="value">{formatNumber(balanceCoin, coinChosen?.symbol, true, true)}</span>
                    </div>
                  </div>
                }
              >
                <div className="withdraw-amount">
                  <Form.Item
                    name="withdrawAmount"
                    noStyle
                    shouldUpdate
                    rules={[
                      {
                        validator: (_rule: any, value: string = '') => {
                          if (value.length === 0) {
                            handleValidate('withdrawAmount', true);
                            return Promise.reject(t('This field is required'));
                          }
                          const valueCoin = formatCurrencyToNumber(value) ?? 0;
                          const coinPrice = Number(coinChosen?.price) ?? 0;
                          if (valueCoin <= 0) {
                            handleValidate('withdrawAmount', true);
                            return Promise.reject(t('The withdraw amount must be greater than 0'));
                          }
                          if (valueCoin > 0 && Math.round(valueCoin * coinPrice) < 20) {
                            handleValidate('withdrawAmount', true);
                            return Promise.reject(t('The withdraw amount must be greater than 20 USDT'));
                          }
                          if (valueCoin > balanceCoin) {
                            handleValidate('withdrawAmount', true);
                            return Promise.reject(t('The withdraw amount exceeds your balance.'));
                          }

                          handleValidate('withdrawAmount', false);
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputCurrency
                      placeholder={t('Enter withdraw amount') || ''}
                      err={validateForm?.get('withdrawAmount')}
                    />
                  </Form.Item>
                  <Button type="link" className="btn-max" onClick={onSelectMaxAmount}>
                    {t('Max')}
                  </Button>
                </div>
              </Form.Item>

              <Form.Item label={t('Wallet address') || ''}>
                <Form.Item
                  name="walletAddress"
                  noStyle
                  rules={[
                    {
                      validator: (_rule: any, value: string = '') => {
                        if (value.length === 0) {
                          handleValidate('walletAddress', true);
                          return Promise.reject(t('This field is required'));
                        }
                        if (value?.length > 0) {
                          if (!web3Utils.isAddress(value)) {
                            if (!tronWeb.isAddress(value)) {
                              handleValidate('walletAddress', true);
                              return Promise.reject(t('Wallet is not correct'));
                            }
                          }
                        }
                        handleValidate('walletAddress', false);
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNormal placeholder={t('Enter wallet address') || ''} />
                </Form.Item>
              </Form.Item>
              <Form.Item noStyle>
                <div className="box-fee">
                  <MixTitle
                    title={t('Fee')}
                    isTooltip={true}
                    tooltipValue={t('The amount of fee you will be charged when withdrawing.') || ''}
                    titleWeight={400}
                  />

                  <span className="value">
                    {coinChosen?.fee}
                    {` ${coinChosen?.symbol}`}
                  </span>
                </div>
              </Form.Item>

              <Form.Item className="titan-form__item mb-0 actions" shouldUpdate>
                {({ getFieldValue, getFieldError }) => {
                  const getDisabled = () => {
                    if (loadingWithdraw) return true;
                    if (
                      !getFieldValue('walletAddress') ||
                      !getFieldValue('withdrawAmount') ||
                      !getFieldValue('token') ||
                      !getFieldValue('network')
                    )
                      return true;
                    if (getFieldError('walletAddress')?.length || getFieldError('withdrawAmount')?.length) return true;
                  };
                  return (
                    <Button type="primary" htmlType="submit" className="titan-form__submit" disabled={getDisabled()}>
                      {t('Withdraw')}
                    </Button>
                  );
                }}
              </Form.Item>
            </Form>
          </div>
        </Dialog>
      )}

      {dialogSuccess && (
        <Dialog isOpen={dialogSuccess} handleClose={() => toggleDialogSuccess()} className="titan-modal">
          <>
            <div className="titan-modal__content center">
              <div className="box-icon">
                <SuccessIcon className="icon success" />
              </div>
              <div className="title ">{t('Your withdraw request is being processed')}</div>
              <div className="description">
                <p>
                  {t(
                    'The process will be completed within 24 hours. You will receive notification when the process is completed.',
                  )}
                </p>
              </div>
            </div>
            <div className="titan-modal__footer">
              <div className="actions center">
                <Button type="primary" className="submit" onClick={handleSuccessWithdraw}>
                  {t('OK')}
                </Button>
              </div>
            </div>
          </>
        </Dialog>
      )}
    </>
  );
};

export default DialogWithdraw;
