import { ApiHelper } from '@/utils';

import {
  IParamLogin,
  IParamForgotPassword,
  IParamResetPassword,
  IResponseAuth,
  IParamsChangePassword,
  IParamConfirmCodeForgotPassword,
  IParamResetFirstLogin,
} from './auth.interface';
import { IResponse } from '../common.services';
import { IUser } from '../user';

class AuthServiceRoute {
  static readonly LOGIN_AUTH = '/sub-affiliate/auth/login';

  static readonly VERIFY_ACCOUNT = '/auth/verify-account';

  static readonly REQUEST_FORGOT_AUTH = '/sub-affiliate/auth/forgot-password';

  static readonly CONFIRM_FORGOT_AUTH = '/sub-affiliate/auth/forgot-password/verify';

  static readonly RESET_FORGOT_AUTH = '/sub-affiliate/auth/reset-password';

  static readonly RESET_PASSWORD_FIRST_LOGIN = '/auth/reset-password-first-login';

  static readonly REFRESH_TOKEN_AUTH = '/auth/refresh-token';

  static readonly CHANGE_PASSWORD = '/affiliate-department/auth/change-password';
}

export const loginAuth = async (params: IParamLogin) => {
  return ApiHelper.post<IResponse<IResponseAuth>, IParamLogin>(AuthServiceRoute.LOGIN_AUTH, params);
};

export const refreshTokenAuth = async (refreshToken: string) => {
  const bodyRequest = { refreshToken };
  return ApiHelper.post<IResponse<IUser>, typeof bodyRequest>(AuthServiceRoute.REFRESH_TOKEN_AUTH, bodyRequest);
};

export const requestForgotPassword = async (payload: IParamForgotPassword) => {
  return ApiHelper.post<IResponse<any>, typeof payload>(AuthServiceRoute.REQUEST_FORGOT_AUTH, payload);
};

export const requestConfirmCodeResetPassword = async (payload: IParamConfirmCodeForgotPassword) => {
  return ApiHelper.post<IResponse<any>, typeof payload>(AuthServiceRoute.CONFIRM_FORGOT_AUTH, payload);
};

export const resetForgotPassword = async (payload: IParamResetPassword) => {
  return ApiHelper.post<IResponse<any>, typeof payload>(AuthServiceRoute.RESET_FORGOT_AUTH, payload);
};

export const resetPasswordFirstLogin = async (payload: IParamResetFirstLogin) => {
  return ApiHelper.post<IResponse<any>, typeof payload>(AuthServiceRoute.RESET_PASSWORD_FIRST_LOGIN, payload);
};

export const changePassword = async (params: IParamsChangePassword) => {
  return ApiHelper.post<IResponse<IUser>, IParamsChangePassword>(AuthServiceRoute.CHANGE_PASSWORD, params);
};

export const logout = async () => {
  window.localStorage.clear();
};
