import { TickSuccessIcon } from '@/assets';
import { Dialog } from '@/components';
import { Button, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  showProcessing: boolean;
  loading: boolean;
  close: () => void;
};

const ClaimCommission = ({ open, close, showProcessing, loading }: Props): React.JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialog isOpen={open} isCloseButton handleClose={() => close()} className="titan-modal">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 246 }}>
          <Spin />
        </div>
      ) : (
        <>
          <div className="titan-modal__content center">
            <div className="box-icon">
              <TickSuccessIcon />
            </div>
            <div className="title ">
              {showProcessing ? t('Your claim request is being processed') : t('Commission claimed successfully!')}
            </div>
            <div className="description">
              <p>
                {showProcessing
                  ? t('Claim request bigger than 1,000.00 USDT will need to be approved by Top trading admin.')
                  : t('You have successfully claim all your commissions. You can claim commission again in 7 days.')}
              </p>
            </div>
          </div>
          <div className="titan-modal__footer">
            <div className="actions center">
              <Button className="submit" style={{ color: '#000' }} onClick={close}>
                {t('Got it')}
              </Button>
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
};

export default ClaimCommission;
