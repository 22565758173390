import React from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';

type IPropsTab = {
  items: TabsProps['items'];
} & TabsProps;

const TabNormal: React.FC<IPropsTab> = ({ ...rest }) => <Tabs style={{ flex: 1 }} {...rest} />;

export default TabNormal;
