import React from 'react';

import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { commonCloseError } from '@/store/common/actions';
import { ErrorState } from '@/store/common/selector';

import { CloseCircleWarning } from '@/assets';
import { Dialog } from './Dialog';
import { useTranslation } from 'react-i18next';

export const DialogError = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const error = useSelector(ErrorState);
  const handleCloseError = () => {
    dispatch(commonCloseError());
    if (!error.action) return;
    if (error.action) {
      dispatch({ type: error.action });
    }
  };

  return (
    <>
      <Dialog isOpen={error.open} handleClose={() => handleCloseError()} className="titan-modal">
        <>
          <div className="titan-modal__content center">
            <div className="box-icon">
              <CloseCircleWarning className="icon warning" />
            </div>
            <div className="title ">{error.message}</div>

            {error.errorCode === 'NO_COMMISSION_RATE' && (
              <div className="description ">
                {t('Before referring users, please get in touch with the CEO to discuss adding your commission rate.')}
              </div>
            )}
          </div>
          <div className="titan-modal__footer">
            <div className="actions center">
              <Button type="primary" className="submit danger" onClick={() => handleCloseError()}>
                {t('Got It')}
              </Button>
            </div>
          </div>
        </>
      </Dialog>
    </>
  );
};
export default DialogError;
