import { ApiHelper } from '@/utils';
import { IListResponse, IQueryParam, IResponse } from '../../common.services';
import { IAsset, ITransaction } from './my-wallet.interface';

class MyWalletServiceRoute {
  static readonly MY_WALLET = '/titan-balances/affiliate-department/my-wallet';

  static readonly ASSETS = '/titan-balances/affiliate-department/assets';

  static readonly TRANSACTIONS = '/transactions/my-transactions';
}

export const getMyWallet = async () => {
  return ApiHelper.get<IResponse<any>>(MyWalletServiceRoute.MY_WALLET);
};

export const getListAssets = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<IAsset>>(MyWalletServiceRoute.ASSETS, undefined, query);
};

export const getListTransactions = async (query?: IQueryParam[]) => {
  return ApiHelper.get<IListResponse<ITransaction>>(MyWalletServiceRoute.TRANSACTIONS, undefined, query);
};
